import { OrderProductStatusesEnum, OrderSubStatesEnum } from "@/api-client";
import { TagType } from "element-ui/types/tag";

export function appendLeadingZeroes(n:any){
  if(n <= 9){
    return "0" + n;
  }
  return n
}

export function formatName(str: string): string {
  if(str) {
    const res = str.replace(/([A-Z])/g, " $&");
    return res.trim();
  }
  return "";
}

export type ElementUITypes = Exclude<TagType, "gray"> | "info";

export const orderSubStatesFilter = (status: OrderSubStatesEnum) => {
  const statusMap: Record<OrderSubStatesEnum, ElementUITypes > = {
    PendingActivation: 'warning',
    PendingCancellation: 'warning',
    PendingSuspension: 'warning',
    PendingMigration: 'warning',
    Suspended: 'danger',
    Activated: 'success',
    Cancelled: 'danger',
    PendingInitialPayment: 'warning',
    NewOrder: 'info',
    Migrated: 'primary',
    Abandoned: 'danger'
  };
  return statusMap[status];
}

export const orderProductStatusesFilter = (status: OrderProductStatusesEnum) => {
  const statusMap: Record<OrderProductStatusesEnum, ElementUITypes > = {
    PendingMigration: 'warning',
    Suspended: 'danger',
    Activated: 'success',
    Cancelled: 'danger',
    New: 'info',
    Migrated: 'primary',
    Abandoned: 'danger',
  };
  return statusMap[status];
}