
import { OrderProductGroupedQueuesViewModel, OrderProductGroupedQueuesViewModelOrderProductQueues } from "@/api-client";
import { ordersQueuesGet } from "@/api/orders";
import Logo from "@/components/Logo/index.vue";
import store from "@/store";
import { RecentlyViewedItem, RecentlyViewedItemType } from "@/store/modules/user";
import { formatName } from "@/utils";
import { Component, Vue } from "vue-property-decorator";

type TooltipTip = Record<keyof OrderProductGroupedQueuesViewModelOrderProductQueues, string>;
type RecentlyViewedDetail = {
  routeName: string,
  iconClass: string
}

@Component({
  name: "OrdersDashboard",
  components: {
    Logo,
  },
})
export default class extends Vue {
  dashboardComponents: OrderProductGroupedQueuesViewModel = {
    orderProductQueues: {
      paymentDue: 0,
      paidUp: 0,
      pendingSuspension: 0,
      pendingCancelation: 0,
      suspendTelkomErrors: 0,
      cancelTelkomErrors: 0,
      pendingTelkomActivation: 0,
      newlyActivated: 0,
      pendingInitialPayment: 0,
    },
    newOrders: [],
    activeOrders: [],
    suspendedOrders: [],
    cancelledOrders: [],
  };
  tooltipTips: TooltipTip = {
    pendingInitialPayment: "New orders that still need to be paid.",
    pendingTelkomActivation: "An order pending activation on telkom's system.",
    newlyActivated: "Newly activated orders on the OMS.",
    suspendTelkomErrors: "Errors on Telkom's system for suspending an order.",
    paymentDue: "All orders that have a payment due.",
    paidUp: "All orders that have settled their payments.",
    pendingSuspension: "Orders where service has been requested for suspension.",
    pendingCancelation: "Orders where service has been requested for cancellation.",
    cancelTelkomErrors: "Errors on Telkom's system for cancelling an order."
  };
  recentlyViewed: Record<string, RecentlyViewedItem> | null = null
  recentlyViewedDetails: Record<RecentlyViewedItemType, RecentlyViewedDetail> = {
    Order: {
      routeName: "OrderDetail",
      iconClass: "el-icon-tickets"
    },
    Product: {
      routeName: "ProductDetail",
      iconClass: "el-icon-goods"
    },
    User: {
      routeName: "UserDetail",
      iconClass: "el-icon-user-solid"
    },
    Customer: {
      routeName: "CustomerDetail",
      iconClass: "el-icon-user"
    }
  }

  dashboardComponentKeys: Array<keyof OrderProductGroupedQueuesViewModelOrderProductQueues> = ["pendingInitialPayment", "pendingTelkomActivation", "newlyActivated", "paymentDue", "paidUp", "pendingSuspension", "suspendTelkomErrors", "pendingCancelation"];
  // Get all elements with the 'dashboard-value-wrap' class

  get userId() {
    return store.getters['user/userId']
  }

  async created() {
    this.dashboardComponents = await ordersQueuesGet();
    this.getRecentlyViewed()
  }

  mapToObject<K, V> (map: Map<K, V>): Record<string, RecentlyViewedItem> {
    return Object.fromEntries(map.entries());
  }

  getRecentlyViewed() {
    const map = store.getters['user/recentlyViewed']
    let obj: any = null;
    if(map?.size) {
      obj = this.mapToObject(map)
    }
    this.recentlyViewed = obj
  }

  async clearRecentlyViewed() {
    await store.dispatch("user/clearRecentlyViewed").then(() => {
      this.getRecentlyViewed()
    })
    
  }

  handleName(name: string) {
    return formatName(name);
  }

  formatKeyUpper(key: keyof OrderProductGroupedQueuesViewModelOrderProductQueues) {
    if (key.length === 0) {
      return key; // If key is empty, return it as is
    }
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  formatKeyLower(key: keyof OrderProductGroupedQueuesViewModelOrderProductQueues) {
    if (key.length === 0) {
      return key; // If key is empty, return it as is
    }
    return key.charAt(0).toLowerCase() + key.slice(1);
  }
}
